
import { Component, Vue } from "vue-property-decorator";
import { email, required } from "@/helpers/rules";
import { TokenType } from "@/models/utils/check-token-request.interface";
import { authenticate } from "@/services/api/authenticate.service";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import AuthModule from "@/store/modules/auth.module";
import ErrorModule from "@/store/modules/error.module";
import EmailModule from "@/store/modules/email.module";
import OverlayModule from "@/store/modules/overlay.module";
const authModule = getModule(AuthModule, store);
const emailModule = getModule(EmailModule, store);
const errorModule = getModule(ErrorModule, store);
const overlayModule = getModule(OverlayModule, store);

@Component
export default class Error extends Vue {
  private errorType = "Errore #404";
  private errorMessage = "Errore durante il caricamento della pagina!";
  private errorSubtitle = null;
  private tokenType = null;
  private valid = false;
  private formEmail = "";
  private isFromLogin = false;

  private email = email;
  private required = required;

  private created(): void {
    const error = errorModule.message;
    if (error) {
      this.errorType = "Errore #" + errorModule.type;
      this.tokenType = errorModule.token;
      if (this.tokenType == TokenType.PASSWORD) {
        this.errorMessage = error;
      } else if (this.tokenType == TokenType.EMAIL) {
        this.isFromLogin = this.$route.params["isFromLogin"] == "true";
        if (this.isFromLogin) {
          this.errorMessage = "Email non confermata!";
          this.errorSubtitle = "Controlla la tua mail o richiedine un'altra.";
        } else {
          this.errorMessage = "La richiesta è scaduta o invalida!";
          this.errorSubtitle = "Richiedi un'altra mail di conferma.";
        }
      }
    }
  }

  private goToHome(): void {
    authModule.logout();
    errorModule.getError(null);
    this.$router.push({ name: "home" });
  }

  private goToPasswordRecovery(): void {
    authModule.logout();
    errorModule.getError(null);
    this.$router.push({ name: "password-recovery" });
  }

  private async emailRequest(): Promise<void> {
    overlayModule.showOverlay();
    emailModule.show();
    await authenticate.requestConfirmEmail(errorModule.message);
    errorModule.getError(null);
    this.$router.push({ name: "login" });
  }

  private async onSubmit(): Promise<void> {
    const isValid = (
      this.$refs.form as Vue & { validate: () => boolean }
    ).validate();
    this.valid = isValid;
    if (isValid) {
      overlayModule.showOverlay();
      emailModule.show();
      await authenticate.requestConfirmEmail(this.formEmail);
      errorModule.getError(null);
      this.$router.push({ name: "login" });
    }
  }
}
